import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Userpilot } from 'userpilot';
import { HttpClient } from '@angular/common/http';
import { DataLayerModel } from '../model/util.model';
import { ConfigService } from '../config/config.service';
import { all_category, CfgCategoryInfo, DEFAULT_HOMEPAGE } from '../config/config.model';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsModel, AnalyticsService } from '@futura/futura-ui';

declare const analytics: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService implements AnalyticsService {
  categories = new Array<CfgCategoryInfo>();

  public lastUrl?: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private configService: ConfigService,
    private gaService: GoogleAnalyticsService,
    private deviceService: DeviceDetectorService
  ) {
    configService.getUiConfig('_categories').subscribe(cats => (this.categories = cats));
  }

  public getFuturaVersion(): Observable<{ version: string }> {
    return this.http.get<{ version: string }>(`${environment.be_url}/version`);
  }

  public pushDataLayer(event: DataLayerModel): void {
    const provider = window as unknown as { dataLayer?: Array<unknown> };
    provider?.dataLayer?.push({ event: 'all_event', ...event });
  }

  public async gotoHomePage(queryParams: { [key: string]: string } = {}): Promise<boolean> {
    const homePage = await firstValueFrom(this.configService.getSimulationConfig('_homepage'));
    return this.router.navigate([homePage || DEFAULT_HOMEPAGE], { queryParams });
  }

  public show500(): void {
    this.router.navigate(['/500']);
  }

  isMobileDevice(): boolean {
    let check: boolean = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  isDesktopDevice(): boolean {
    if (typeof window !== 'undefined' && window) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipod|ipad|android|blackberry|opera mini|iemobile|wpdesktop/i.test(userAgent);
      const isTablet = /ipad|android|tablet/i.test(userAgent);

      return !(isMobile || isTablet);
    }

    return false;
  }

  getMilestoneFromName(name: string): CfgCategoryInfo {
    for (const category of this.categories) {
      if (name.split('.')[0] == category.category_name) return category;
    }
    return all_category;
  }

  async sendEvent(
    eventName: string,
    eventParams: {
      [key: string]: any;
    } = {},
    useUserpilot = false,
    useSegment: boolean = true
  ) {
    eventParams['platform'] = environment.platform;
    eventParams['page'] = location.pathname;
    eventParams['device'] = this.deviceService.deviceType;

    const gtagData = JSON.parse(localStorage.getItem('gtagData') || '{}') || {};

    Object.entries(gtagData).forEach(([key, value]) => {
      if (!eventParams[key]) eventParams[key] = value;
    });

    this.gtag('event', (eventName || '').toLowerCase(), eventParams);
    if (useSegment) analytics.track(eventName, eventParams);
    if (useUserpilot) Userpilot.track(eventName, eventParams);
  }

  sendAnalytics(analytics: AnalyticsModel): void {
    const { eventName, eventParams } = analytics;
    this.sendEvent(eventName, eventParams);
  }

  public eventCategoriesFormatted(categories: Array<string> | string): { [key: string]: string } {
    const catArray = typeof categories === 'string' ? categories.split(',') : categories;

    let cat1 = new Array<string>();
    let cat2 = new Array<string>();
    let cat3 = new Array<string>();

    for (const cat of catArray) {
      const categorySplitted = cat.split('.');

      // if (categorySplitted.length > 0) cat1.push(categorySplitted[0]);
      // if (categorySplitted.length > 1) cat2.push(categorySplitted[1]);
      // if (categorySplitted.length > 2) cat3.push(categorySplitted[2]);

      if (categorySplitted[0] !== undefined) cat1.push(categorySplitted[0]);
      if (categorySplitted[1] !== undefined) cat2.push(categorySplitted[1]);
      if (categorySplitted[2] !== undefined) cat3.push(categorySplitted[2]);
    }

    cat1 = Array.from(new Set(cat1));
    cat2 = Array.from(new Set(cat2));
    cat3 = Array.from(new Set(cat3));

    while (cat1.join(',').length > 255) cat1.pop();
    while (cat2.join(',').length > 255) cat2.pop();
    while (cat3.join(',').length > 255) cat3.pop();

    return {
      cat1: cat1.join(','),
      cat2: cat2.join(','),
      cat3: cat3.join(','),
    };
  }

  public gtag(
    eventType: string,
    eventName: string,
    eventParams: {
      [key: string]: any;
    } = {}
  ) {
    this.gaService.gtag(eventType, (eventName || '').toLowerCase(), eventParams);
  }
}
